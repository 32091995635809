<template>
  <div
    class="mt-10 w-full md:w-9/12 font-proximaMedium md:border-l-2 border-lightGrey40"
  >
    <ul class="text-center flex items-center justify-evenly">
      <li
        class="w-full md:w-4/12 text-xl font-bold md:border-r border-lightGrey40"
      >
        Matching Result: {{ data.length }}
      </li>
      <li
        class="hidden md:block w-3/12 text-xl font-bold border-r border-lightGrey40"
      >
        Distance
      </li>
      <li
        class="hidden md:block w-3/12 text-xl font-bold border-r border-lightGrey40"
      >
        Availability
      </li>
      <li class="hidden md:block w-3/12 text-xl font-bold">Rating</li>
    </ul>
    <div class="md:ml-2 mt-2">
      <span v-if="!data">No data</span>
      <ResultListEach v-else v-for="(d, i) in data" :key="i" :data="d" />
    </div>
  </div>
</template>

<script>
import ResultListEach from "./ResultListEach.vue";

export default {
  props: ["data"],
  components: {
    ResultListEach,
  },
 
};
</script>

<style></style>
