<template>
  <div
    class="
      w-11/12
      md:w-10/12
      flex flex-wrap
      items-center
      justify-between
      font-proximaMedium
    "
  >
    <div class="mb-4 w-full block md:hidden">
      <div class="relative">
        <input
          class="w-full py-3 px-4 rounded-md"
          type="text"
          placeholder="Search999"
        />
        <img
          class="h-4 absolute top-4 right-2"
          src="@/assets/images/search-icon.png"
          alt="search-icon"
        />
      </div>
    </div>
    <div
      class="
        w-44
        md:w-4/12
        lg:w-52
        py-3
        px-6
        hidden
        md:flex
        items-center
        justify-between
        bg-white
        rounded-md
        cursor-pointer
      "
    >
      <input class="w-10/12 md:w-8/12" type="text" placeholder="Postcode" />
      <LocationIcon
        class="ml-4 mt-1"
        iconColor="#0AC0CB"
        heigth="18"
        width="18"
      />
    </div>
    <div class="md:w-5/12 lg:w-6/12 hidden md:block">
      <div class="relative">
        <input
          class="w-full py-3 px-4 rounded-md"
          type="text"
          placeholder="Search"
        />
        <img
          class="h-4 absolute top-4 right-2"
          src="@/assets/images/search-icon.png"
          alt="search-icon"
        />
      </div>
    </div>
    <div
      class="
        w-44
        md:w-52
        py-0
        px-6
        hidden
        md:flex
        items-center
        justify-between
        bg-white
        rounded-md
        cursor-pointer
        relative
      "
    >
      <div class="relative">
        <span @click="showSort = !showSort"
          >Sort by: {{ this.sortByTitle }}</span
        >
        <span
          v-if="!showSort"
          class="text-xs absolute top-4 md:top-1 -right-1 md:-right-5"
          >&#9660;</span
        >
        <span
          v-else
          class="text-xs absolute top-4 md:top-1 -right-1 md:-right-5"
          >&#9650;</span
        >
      </div>
      <ul
        class="p-7 text-black bg-white rounded-md top-14 right-0 absolute z-10"
        v-if="showSort"
      >
        <li v-for="(data, i) in sortBy" :key="i">
          <InputRadioEach
            :title="data.title"
            :id="data.id"
            :checked="data.checked"
            @inputVal="inputValChange"
          />
        </li>
      </ul>
    </div>
    <div class="w-full flex md:hidden items-start justify-between">
      <FilterSection class="md:hidden" />
      <div>
        <div
          class="
            w-44
            md:w-4/12
            lg:w-52
            py-3
            px-6
            flex
            md:hidden
            items-center
            justify-between
            bg-white
            rounded-md
            cursor-pointer
          "
        >
          <input class="w-10/12 md:w-8/12" type="text" placeholder="Postcode" />
          <LocationIcon
            class="ml-4 mt-1"
            iconColor="#0AC0CB"
            heigth="18"
            width="18"
          />
        </div>
        <div
          class="
            w-44
            md:w-52
            py-0
            md:py-3
            px-6
            mt-2
            flex
            items-center
            justify-between
            bg-white
            rounded-md
            cursor-pointer
            relative
          "
          :class="[sortByTitle ? ['border', 'border-primary'] : '']"
        >
          <div class="h-12 flex items-center justify-center relative">
            <span
              @click="showSort = !showSort"
              :class="[sortByTitle ? ['text-primary'] : '']"
              >Sort by: {{ this.sortByTitle }}</span
            >
            <!-- <span v-if="!showSort" class="text-xs absolute top-4 md:top-1 -right-4 md:-right-5">&#9660;</span>
        <span v-else class="text-xs absolute top-4 md:top-1 -right-4 md:-right-5">&#9650;</span> -->
          </div>
          <ul
            class="
              p-7
              text-black
              bg-white
              rounded-md
              top-14
              right-0
              absolute
              z-10
            "
            v-show="showSort"
          >
            <li v-for="(data, i) in sortBy" :key="i">
              <InputRadioEach
                :title="data.title"
                :id="data.id"
                :checked="data.checked"
                @inputVal="inputValChange"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LocationIcon from "../../icons/LocationIcon.vue";
import InputRadioEach from "../../forms/InputRadioEach.vue";
import FilterSection from "./FilterSection.vue";

export default {
  components: {
    LocationIcon,
    InputRadioEach,
    FilterSection,
  },
  data() {
    return {
      showSort: false,
      sortByTitle: "Distance",
      sortBy: [
        { title: "Distance", id: 1, checked: false },
        { title: "Availability", id: 2, checked: false },
        { title: "Rating", id: 3, checked: false },
        { title: "Price Range", id: 4, checked: false },
      ],
    };
  },
  computed: {
    dataSort: function () {
      if (this.sortByTitle == "Distance") {
        function compare(a, b) {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        }
        return this.data.sort(compare);
      }
    },
  },
  methods: {
    inputValChange(i, e) {
      console.log(i, e);
      this.$emit("sortDataBy", i);
      this.sortByTitle = e;
    },
  },
};
</script>

<style>
</style>