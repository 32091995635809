<template>
  <div class="pb-12">
    <div class="flex justify-start items-center">
      <div class="md:w-6/12 flex justify-start">
        <img
          class="w-max h-32 ml-28 mt-0 hidden md:block"
          src="@/assets/images/dataservice-logo.svg"
          alt="dataservice-logo"
        />
      </div>
      <div class="md:w-5/12">
        <HeaderGlobal
          :titlemenu1="$t('global_header_services')"
          titlemenu2="Search for Services"
          :to2="'/browseservicedirectory'"
          titlemenu3="Search Results"
          class="pl-4"
        />
      </div>
    </div>
    <div class="mt-12 flex flex-col justify-center items-center">
      <TopSection @sortDataBy="sortDataBy" />
      <div
        class="flex flex-col md:flex-row items-start justify-between w-11/12 md:w-10/12"
      >
        <FilterSection class="hidden md:block" :data="serviceDirectory" />
        <ResultListSection :data="dataSort" />
      </div>
    </div>
  </div>
</template>

<script>
import HeaderGlobal from "../../header/HeaderGlobal.vue";
import TopSection from "./TopSection.vue";
import FilterSection from "./FilterSection.vue";
import ResultListSection from "./ResultListSection.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    HeaderGlobal,
    TopSection,
    FilterSection,
    ResultListSection,
  },
  data() {
    return {
      data: this.serviceDirectory,
      val: 1,
    };
  },
  computed: {
    ...mapGetters({
      serviceDirectory: "servicedirectory/getServiceDirectories",
    }),

    dataSort: function () {
      if (this.val == 1) {
        function compare(a, b) {
          if (a.dist_in_km < b.dist_in_km) return -1;
          if (a.dist_in_km > b.dist_in_km) return 1;
          return 0;
        }
        return this.serviceDirectory.sort(compare);
      } else if (this.val == 2) {
        function compare(a, b) {
          if (a.lastName < b.lastName) return -1;
          if (a.lastName > b.lastName) return 1;
          return 0;
        }
        return this.serviceDirectory.sort(compare);
      } else if (this.val == 3) {
        function compare(a, b) {
          if (a.lastName < b.lastName) return -1;
          if (a.lastName > b.lastName) return 1;
          return 0;
        }
        return this.serviceDirectory.sort(compare);
      } else if (this.val == 4) {
        function compare(a, b) {
          if (a.lastName < b.lastName) return -1;
          if (a.lastName > b.lastName) return 1;
          return 0;
        }
        return this.serviceDirectory.sort(compare);
      } else if (this.val == 5) {
        function compare(a, b) {
          if (a.lastName < b.lastName) return -1;
          if (a.lastName > b.lastName) return 1;
          return 0;
        }
        return this.serviceDirectory.sort(compare);
      }
    },
  },
  methods: {
    sortDataBy(val) {
      this.val = val;
      console.log(val);
    },
  },
};
</script>

<style></style>
