<template>
  <div class="w-full md:w-3/12 mt-0 md:mt-10 py-10 font-proximaMedium">
    <div class="hidden md:flex items-center justify-start">
      <img src="@/assets/images/filter-icon.png" alt="share-icon" />
      <h3 class="pl-2 text-xl">Filter Results</h3>
    </div>
    <div class="hidden md:block">
      <ul class="my-6 font-proximaLight">
        <li class="text-base text-left my-3">
          <label class="container"
            >{{ $t("service_directoty_filter_section_1") }}
            <input type="checkbox" :checked="filterLanguage" />
            <span class="checkmark"></span>
          </label>
        </li>
        <li class="text-base text-left my-3">
          <label class="container"
            >{{ $t("service_directoty_filter_section_3") }}
            <input type="checkbox" :checked="filterPaymentcc" />
            <span class="checkmark"></span>
          </label>
        </li>
        <li class="text-base text-left my-3">
          <label class="container"
            >Deferred Payment
            <input type="checkbox" :checked="filterDeffPayment" />
            <span class="checkmark"></span>
          </label>
        </li>
        <li class="text-base text-left my-3">
          <label class="container"
            >{{ $t("service_directoty_filter_section_2") }}
            <input type="checkbox" :checked="filterService" @change="filterServiceChange" />
            <span class="checkmark"></span>
          </label>
        </li>
        <li class="text-base text-left my-3">
          <label class="container"
            >Loyalty Program
            <input type="checkbox" :checked="filterLoyalty" @change="loyaltyProgramChange" />
            <span class="checkmark"></span>
          </label>
        </li>
      </ul>
      <SubmitButton class="m-auto" title="Update" width="w-max" paddingX="px-10" />
    </div>

    <!-- mobile view -->
    <div class="flex md:hidden items-center justify-center cursor-pointer relative" @click="toggleFilter = !toggleFilter">
      <img src="@/assets/images/filter-icon.png" alt="share-icon" />
      <h3 class="pl-2 text-xl">Filter Results</h3>
      <span v-if="!toggleFilter" class="text-xs ml-2">&#9660;</span>
      <span v-else class="text-xs ml-2">&#9650;</span>
    </div>
    <div v-if="toggleFilter" class="md:hidden">
      <ul class="my-6 font-proximaLight">
        <li class="text-base text-left my-3">
          <label class="container"
            >{{ $t("service_directoty_filter_section_1") }}
            <input type="checkbox" :checked="filterLanguage" />
            <span class="checkmark"></span>
          </label>
        </li>
        <li class="text-base text-left my-3">
          <label class="container"
            >{{ $t("service_directoty_filter_section_3") }}
            <input type="checkbox" :checked="filterPaymentcc" />
            <span class="checkmark"></span>
          </label>
        </li>
        <li class="text-base text-left my-3">
          <label class="container"
            >Deferred Payment
            <input type="checkbox" :checked="filterDeffPayment" />
            <span class="checkmark"></span>
          </label>
        </li>
        <li class="text-base text-left my-3">
          <label class="container"
            >{{ $t("service_directoty_filter_section_2") }}
            <input type="checkbox" :checked="filterService" @change="filterServiceChange" />
            <span class="checkmark"></span>
          </label>
        </li>
        <li class="text-base text-left my-3">
          <label class="container"
            >Loyalty Program
            <input type="checkbox" :checked="filterLoyalty" @change="loyaltyProgramChange(val)" />
            <span class="checkmark"></span>
          </label>
        </li>
      </ul>
      <SubmitButton class="m-auto" title="Update" width="w-max" paddingX="px-10" />
    </div>
    <!-- mobile view end -->
  </div>
</template>

<script>
import SubmitButton from "../../buttons/SubmitButton.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  props: { data: {} },
  components: {
    SubmitButton,
  },
  data() {
    return {
      filterLanguage: false,
      filterPaymentcc: false,
      filterDeffPayment: false,
      filterService: false,
      filterLoyalty: false,
      toggleFilter: false,
    };
  },

  methods: {
    ...mapActions({
      filterServiceDirectory: "servicedirectory/filterServiceDirectory",
    }),
    loyaltyProgramChange(val) {
      console.log(val);
    },

    filterServiceChange() {
      console.log("777777777777");
      this.filterServiceDirectory(!this.filterService);
    },
  },
};
</script>

<style scoped>
/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #fff;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #0ac0cb;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 7px;
  top: 4px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
