<template>
  <div
    class="mt-5 py-6 md:py-10 bg-white border-l-4 border-primary md:border-0 rounded-md flex flex-wrap items-center justify-evenly font-proximaLight"
  >
    <div
      class="w-full md:w-4/12 mb-2 md:mb-0 pl-4 md:border-l-4 border-primary flex items-start relative"
    >
      <div class="flex flex-col items-center">
        <div class="w-7 h-7">
          <img
            src="@/assets/images/profilepic-icon.png"
            alt="profilepic-icon"
          />
        </div>
        <img
          class="my-2"
          src="@/assets/images/approval-shield-icon.png"
          alt="approval-shield-icon"
        />
        <HeartIcon
          class="hidden md:block bg-lightGrey20 rounded-full p-1 cursor-pointer"
          width="24"
          height="24"
          :iconColor="favoriteColor"
          @click.native="clickfav(data)"
        />
      </div>
      <div @click="goTo" class="ml-2 text-left">
        <h5 class="text-primary font-bold cursor-pointer">
          {{ data.businessName || data.practiceName }}
        </h5>
        <div>
          <span class="text-sm">{{ data.streetAddress }}</span>
          <br />
          <span class="text-sm">
            {{ data.postalCode + " " + data.state + ", " + data.country }}</span
          >
        </div>
      </div>
      <HeartIcon
        class="block md:hidden bg-lightGrey20 rounded-full p-1 absolute right-4 -top-3 cursor-pointer"
        width="24"
        height="24"
        :iconColor="favoriteColor"
        @click.native="clickfav"
      />
    </div>
    <div class="w-4/12 md:w-3/12">
      <!-- <span>{{ data.distance }}</span> -->
      <span> {{ data.dist_in_km + " Km" }} </span>
    </div>
    <div
      class="w-4/12 md:w-3/12 border-l-2 border-r-2 border-lightGrey20"
      :class="availabilityColor"
    >
      <!-- <span>{{ data.availability }}</span> -->
      <span>Open</span>
    </div>
    <div class="w-4/12 md:w-3/12 relative">
      <star-rating
        class="flex justify-center"
        :star-size="20"
        :rating="2.5"
        :read-only="true"
        :show-rating="false"
        :increment="0.01"
        active-color="#0AC0CB"
      />
    </div>
  </div>
</template>

<script>
import StarRating from "vue-star-rating";
import HeartIcon from "../../icons/HeartIcon.vue";
import {
  addToFavouriteServices,
  removeFromFavouriteServices,
} from "../../../services/commonService";

export default {
  props: ["data"],
  components: {
    StarRating,
    HeartIcon,
  },
  data() {
    return {
      url: `/businessprofileview/${this.data.emailAddress}`,
    };
  },
  computed: {
    availabilityColor() {
      if (this.data.availability == "open") {
        return "text-primary";
      } else if (this.data.availability == "closed") {
        return "text-red";
      } else {
        return "text-orange";
      }
    },

    favoriteColor() {
      if (this.data.is_friends_fav) {
        return "#ff0c00";
      } else {
        return "#9ca3af";
      }
    },
  },
  methods: {
    async clickfav(data) {
      console.log(data);
      data.is_friends_fav = !data.is_friends_fav;
      if (data.is_friends_fav == true) {
        await addToFavouriteServices(data.emailAddress);
      } else {
        await removeFromFavouriteServices(data.emailAddress);
      }

      console.log("------------", data);
    },
    goTo() {
      this.$router.push({ path: this.url });
    },
  },
};
</script>

<style></style>
